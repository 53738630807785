
import { IonButtons, IonContent, IonMenuButton, IonPage } from '@ionic/vue';
import axios from "axios";
import { jsonDataURL } from '@uinterview/upolitics'

export default {
	name: 'Folder',
	components: {
		IonButtons,
		IonContent,
		IonMenuButton,
		IonPage,
	},
	data() {
		return {
			componentKey: 0,
			article: []
		};
	},
	mounted() {
		const d = new Date
		this.$refs.copyYear.innerHTML = d.getFullYear()
		if (localStorage.appData) {
			const dateNow = Date.now()
			const jsonExpire = (JSON.parse(localStorage.appData).expires)*1000
			if(dateNow <= jsonExpire) {
				this.entriesList = JSON.parse(localStorage.appData)
				this.article = this.entriesList.pages[this.$route.params.id]
			} else {
				axios.get(jsonDataURL)
					.then(response => {
						this.entriesList = response.data
						localStorage.setItem('appData', JSON.stringify(response.data));
						this.entriesList = JSON.parse(localStorage.appData)
						this.article = this.entriesList.pages[this.$route.params.id]
					})
			}
		} else {
			axios.get(jsonDataURL)
				.then(response => {
					this.entriesList = response.data
					localStorage.setItem('appData', JSON.stringify(response.data));
					this.article = this.entriesList.pages[this.$route.params.id]
				})
		}
	},
	watch: {
		'$route' (to) {
			if(to.path.includes('/contentpage/')){
				if (localStorage.appData) {
					this.entriesList = JSON.parse(localStorage.appData)
					this.article = this.entriesList.pages[this.$route.params.id]
				} else {
					axios.get(jsonDataURL)
						.then(response => {
							this.entriesList = response.data
							localStorage.setItem('appData', JSON.stringify(response.data));
							this.article = this.entriesList.pages[this.$route.params.id]
								})
				}

			}
			this.$el.querySelector("#container").scrollIntoView({behavior: 'smooth'});
		}
	},
	methods: {
		sendEmail(){
			window['plugins'].socialsharing.shareViaEmail(
				'', // can contain HTML tags, but support on Android is rather limited:  http://stackoverflow.com/questions/15136480/how-to-send-html-content-with-image-through-android-default-email-client
				'uPolitics App',
				['editor@uinterview.com'], // TO: must be null or an array
				null, // CC: must be null or an array
				null, // BCC: must be null or an array
			);
		}
	},
};
